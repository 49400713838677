<script setup>
import { ref, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useConnectorsStore, useLogsStore, useModelsStore, useDecisionsStore } from '@/stores';
import { useToast } from 'primevue/usetoast';
import { VueJsonPretty } from '@/components';
import { useDateFormat } from '@/composables';
import { useRoute } from 'vue-router';
import { router } from '@/router';
import Image from 'primevue/image';
const route = useRoute(); 
const id = route.params.id;
const decisionsStore = useDecisionsStore();
const connectorsStore = useConnectorsStore();
const modelsStore = useModelsStore();
const logsStore = useLogsStore();
const { freeConnectors, unallocatedClusters } = storeToRefs(connectorsStore);
const { isLoaded, model, clusterSelected, decision, hasClusterConnector, hasLogs, selectedClusterID, decisionAvailable, modelMessages, modelActivePanel } = storeToRefs(modelsStore);
const toast = useToast();
const { genAllAPIDocs, assignCluster, unassignCluster, testAllConnectors, updateModel } = modelsStore;
const { refreshConnectorById } = connectorsStore;
const modelPrettyName = ref('Model Details');
const inputPrettyName = ref('Selected Decision Input');
const addCluster = ref(false);
const RequestAPIChange = ref(false);
const selectedInput = ref({});
const selectedConnector = ref();
 

onMounted(() => {
    modelMessages.value = [];
    selectedClusterID.value = '';
    addCluster.value = false;
});

const up2tomUrl = computed(() => {
    return `/layout/images/${model.value.id}.png`;
});

const assignClusterConnector = () => {
    toast.add({ severity: 'info', summary: 'Decision Cluster Request Received', life: 3000 });
    console.log('assignClusterConnector modelsStore model_id, modelid, selectedClusterID', model.value._id, model.value.id, selectedClusterID.value);
    assignCluster(model.value._id, selectedClusterID.value, model.value.id);
    addCluster.value = false;
};

const unassignClusterConnector = () => {
    toast.add({ severity: 'info', summary: 'Decision Cluster Release Request Received', life: 3000 });
    console.log('unassignClusterConnector modelsStore model_id, modelid, selectedClusterID', model.value._id, model.value.attributes.connector);
    unassignCluster(model.value._id, model.value.attributes.connector);
};


const connectorSelected = computed(() => {
    return !(selectedConnector.value == '');
});

const inputSelectionMade = computed(() => {
    return !(Object.entries(selectedInput.value).length === 0);
});

const inputContinuous = computed(() => {
    return selectedInput.value.type == 'Continuous' ? true : false;
});

const isWebHook = computed(() => {
    return selectedInput.value.webhook;
});

async function saveAPI() {
    console.log('saveAPI ', selectedConnector.value._id, selectedInput.value.name);
    modelsStore.change2API(selectedConnector.value._id, selectedInput.value.name);
    RequestAPIChange.value = false;
    selectedConnector.value = '';
    selectedInput.value = {};
}

async function change2Webhook() {
    await modelsStore.changeToWebhook(selectedInput.value._connector, selectedInput.value.name);
    RequestAPIChange.value = false;
    selectedInput.value = {};
}

const buttonColor = (data) => {
    if (data.tested) {
        return 'success';
    } else {
        return 'danger';
    }
};

const cancelAPI = () => {
    // modelsStore.change2API(selectedConnector.value, selectedInput.value.name);
    RequestAPIChange.value = false;
    selectedConnector.value = '';
};

const onRowSelect = (event) => {
    console.log('onRowSelect selectedInput active', event);
    RequestAPIChange.value = false;
    toast.add({ severity: 'info', summary: 'Model Input Selected', detail: event.data.name, life: 3000 });
};
// function createwebhookCodeSnippet() {
//     return {
//         curl: 'cURL -X POST -L ',
//         dataquote: "'",
//         eol: "'",
//         header: '-H ',
//         content: "'Content-Type: application/json'",
//         bearer: "'Authorization: Bearer ",
//         bearerend: "'",
//         data: '-d ',
//         bodystart: "'" + '{',
//         model: '"' + 'model' + '": "',
//         eoldata: '"' + ',',
//         executeDecision: '"executeDecision": false,',
//         preventActions: '"preventActions": false,',
//         logref: '"logref": false',
//         input: '"' + 'input' + '": {"',
//         value: '"value' + '": ' + '"5' + '" ',
//         valueinput: '":',
//         valueeol: '"},',
//         valueNumericEol: '},',
//         bodyend: "}'"
//     };
// }

const manageConnector = () => {
    console.log('model manageConnector selectedConnector._id =', selectedInput.value._connector);
    refreshConnectorById(selectedInput.value._connector);
    router.push({ path: `/connectors/${selectedInput.value._connector}` });
};

const gotoActions = (id) => {
    router.push({ path: `/actions/modelactions/${id}` });
};


const completeCycleTest = () => {
    toast.add({ severity: 'info', summary: 'Full AI Decision Cycle Test has Started', life: 3000 });
    modelsStore.fullCycleTest()
};

const testConnectors = (id) => {
    toast.add({ severity: 'info', summary: 'Full Connector Test In Progress', life: 3000 });
    testAllConnectors(id);
};

const saveEndpoint = () => {
    let modelParam = {};
    modelParam['attributes.up2tomEndpoint'] = model.value.attributes.up2tomEndpoint;
    updateModel(model.value._id, modelParam);
};


const saveToken = () => {
    let modelParam = {};
    modelParam['attributes.up2tomtoken'] = model.value.attributes.up2tomtoken;
    updateModel(model.value._id, modelParam);
};
const latestLogs = (logRef) => {
    console.log('model.attributes.lastLogRef =', logRef);
    logsStore.updateFilter(logRef);
    router.push({ path: `/logs/logref/${logRef}` });
};

const decisionHistory = (modelid) => {
    decisionsStore.updateFilter(modelid);
    console.log('modelsStore.model.id =', modelid);
    router.push({ path: `/decisions/model/${modelid}` });
};


async function gotoDecisionCluster(connector_id) {
    toast.add({ severity: 'info', summary: 'Cluster Loading', life: 3000 });
    console.log('selected connector', connector_id);
    await connectorsStore.refreshConnectorById(connector_id);
    router.push({ path: `/connectors/${connector_id}` });
};

const metaData = computed(() => {
    return JSON.parse(JSON.stringify(modelsStore.model.attributes.metadata.attributes));
});
</script>

<template>
    <!-- info / action Bar -->
    <div class="grid">
        <div class="col-12 md:col-6">
            <div class="card">
                <Button label="Active" severity="success" class="mb-2 mr-2" />
                <Button label="Not Ready" severity="warning" class="mb-2 mr-2" />
                <Button label="Warnings" severity="error" class="mb-2 mr-2" />
            </div>
        </div>
        <div class="col-12 md:col-6">
            <div class="card">
                <Button label="Gen. API Docs" severity="help" @click="genAllAPIDocs()" class="mb-2 mr-2" />
                <Button label="Validate All Connectors" severity="help" @click="testConnectors(id)" class="mb-2 mr-2" />
                <Button label="AI Decision Actions " severity="info" @click="gotoActions(id)" class="mb-2 mr-2" />
                <Button label="Full AI Cycle Test " severity="help" :disabled="hasClusterConnector" @click="completeCycleTest()" class="mb-2 mr-2" />
            </div>
        </div>
    </div>

    <!-- loading .... -->
    <ProgressSpinner v-if="!isLoaded" aria-label="Loading" />
    <!-- only when model loaded - model info - model inputs -->
    <template v-if="isLoaded">
        <!-- Model Info - logs, full cycle test, decisions,  -->
        <div class="card">
            <div class="flex mb-3 gap-2 justify-content-end">
                <Button @click="modelActivePanel = 0" rounded label="1" class="w-2rem h-2rem p-0" :outlined="modelActivePanel !== 0" />
                <Button @click="modelActivePanel = 1" rounded label="2" class="w-2rem h-2rem p-0" :outlined="modelActivePanel !== 1" />
                <Button @click="modelActivePanel = 2" rounded label="3" class="w-2rem h-2rem p-0" :outlined="modelActivePanel !== 2" />
                <Button @click="modelActivePanel = 3" rounded label="4" class="w-2rem h-2rem p-0" :outlined="modelActivePanel !== 3" />
            </div>
            <Accordion v-model:activeIndex="modelActivePanel">
                <AccordionTab>
                    <template #header>
                        <span> Model Information -  {{  model.attributes.name }}</span>
                    </template>
                    <div class="grid">
                        <div class="col-12 md:col-4">
                            <Card>
                                <template #title>Model Status</template>
                                <template #content>
                                    <p class="m-0">
                                        Data Received -
                                        {{ useDateFormat(model.attributes.inputsLastChange) }}
                                    </p>
                                    <p class="m-0">
                                        Decision Tested -
                                        <i class="pi pi-thumbs-up-fill" style="font-size: 1rem; color: greenyellow"></i>
                                    </p>
                                    <p class="m-0">
                                        Actions Triggered -
                                        {{ useDateFormat(model.attributes.triggerLastChange) }}
                                    </p>
                                    <p class="m-0">
                                        Actions Tested -
                                        <i class="pi pi-thumbs-up-fill" style="font-size: 1rem; color: greenyellow"></i>
                                    </p>
                                    <Button label="Latest Logs " severity="info" @click="latestLogs(model.attributes.lastLogRef)" :disabled="!hasLogs" class="mb-2 mr-2" />
                                </template>
                            </Card>
                        </div>
                        <div class="col-12 md:col-4">
                            <Card>
                                <template #title>Model Info</template>
                                <template #content>
                                    <p class="m-0">
                                        Model Published -
                                        {{ useDateFormat(model.attributes['publish-date']) }}
                                    </p>
                                    <p class="m-0">modelid - {{ model.id }}</p>
                                    <p class="m-0">_modelid - {{ model._id }}</p>
                                    <p class="m-0">Author - {{ model.attributes.publisher }}</p>
                                </template>
                            </Card>
                        </div>
                        <div v-if="decisionAvailable == true" class="col-12 md:col-4">
                            <Card>
                                <template #title>Dicision History</template>
                                <template #content>
                                    <p class="m-0">Last Decision - {{ useDateFormat(decision.attributes.timestamp) }}</p>
                                    <p class="m-0">Decision - " {{ decision.attributes.decision }} "</p>
                                    <p v-if="decision.attributes['meets-confidence']" class="m-0">
                                        Meets Confidence -
                                        <i style="font-size: 1rem; color: greenyellow" class="pi pi-thumbs-up-fill"> </i>
                                    </p>
                                    <p v-if="!decision.attributes['meets-confidence']" class="m-0">
                                        Meets Confidence -
                                        <i style="font-size: 1rem; color: red" class="pi pi-thumbs-down-fill"> </i>
                                    </p>
                                    <p class="m-0">
                                        Confidence Level -
                                        {{ decision.attributes.confidence * 100 }} %
                                    </p>
                                    <Button label="Decision History " severity="info" @click="decisionHistory(model.id)" class="mb-2 mr-2" />
                                </template>
                            </Card>
                        </div>
                    </div>

                </AccordionTab>
                <AccordionTab header="Cluster Configuration">
                    <Button v-if="!hasClusterConnector" :label="addCluster ? 'Cancel' : 'Add Cluster'" severity="help" class="mb-2 mr-2" @click="addCluster = !addCluster" :disabled="hasClusterConnector" />
                    <Button v-if="clusterSelected && addCluster" label="Confirm Cluster" severity="success" class="mb-2 mr-2" @click="assignClusterConnector()" />
                    <Dropdown v-if="addCluster && !hasClusterConnector" v-model="selectedClusterID" :options="unallocatedClusters" optionLabel="name" optionValue="_id"  placeholder="Select Cluster Connector" class="w-full md:w-24rem" />
                    <Button v-if="hasClusterConnector" label="Decision Cluster" severity="warning" class="mb-2 mr-2" @click="gotoDecisionCluster(model.attributes.connector._id)" />
                    <Button v-if="hasClusterConnector" label="Remove Cluster " severity="danger" class="mb-2 mr-2" @click="unassignClusterConnector()" />
                </AccordionTab>
                <AccordionTab header="Feedback">
                    <transition-group name="p-message" tag="div">
                        <Message v-for="msg of modelMessages" :key="msg.id" :severity="msg.severity">{{ msg.content }}</Message>
                    </transition-group>
                </AccordionTab>
                <AccordionTab header="up2tom Configuration">
                    <div class="p-fluid formgrid grid">
                        <div class="field col-12 md:col-6">
                            <label for="endpoint">Decision endpoint</label>
                            <InputGroup>
                                <InputText
                                    id="endpoint"
                                    type="text"
                                    v-model="model.attributes.up2tomEndpoint"
                                />
                                <Button
                                    icon="pi pi-check"
                                    severity="success"
                                    @click="saveEndpoint()"
                                />
                            </InputGroup>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="up2tomToken">up2tom Token</label>
                            <InputGroup>
                                <InputText
                                    id="up2tomToken"
                                    type="text"
                                    v-model="model.attributes.up2tomtoken"
                                />
                                <Button
                                    icon="pi pi-check"
                                    severity="success"
                                    @click="saveToken()"
                                />
                            </InputGroup>
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
        </div>

        <Divider />

        <div class="card">
            <DataTable
                v-model:selection="selectedInput"
                :value="metaData"
                selectionMode="single"
                paginator
                stripedRows
                dataKey="name"
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50]"
                tableStyle="min-width: 50rem"
                :loading="!isLoaded"
                @rowSelect="onRowSelect"
            >
                <template #header>
                    <div class="flex flex-wrap items-center justify-between gap-2">
                        <span class="text-xl font-bold">Decision Inputs</span>
                    </div>
                </template>

                <template #empty> No Input found. </template>
                <template #loading> Loading Model Input data. Please wait. </template>
                <Column field="name" header="Decision Input"></Column>
                <Column field="question" header="Description"></Column>
                <Column field="webhook" header="Type" style="min-width: 10rem">
                    <template #body="{ data }">
                        <Tag :value="data.webhook ? 'Webhook' : 'API'" style="width: 80px; height: 50px; font: bold" :severity="buttonColor(data)" />
                    </template>
                </Column>
                <Column field="lastChange" header="Last Update">
                    <template #body="{ data }">
                        {{ useDateFormat(data.lastChange) }}
                    </template>
                </Column>
                <Column field="value" header="Last Value"></Column>
            </DataTable>
        </div>
    </template>

    <Divider />

    <div class="grid">
        <div class="col-12 md:col-4">
            <div v-if="inputSelectionMade && inputContinuous" class="card p-fluid">
                <h5>Attribute - Continuous</h5>
                <div class="field grid">
                    <label for="lower" class="Low-12 mb-2 md:col-2 md:mb-0">Range Low</label>
                    <div class="col-12 md:col-10">
                        <InputText id="lower" v-model="selectedInput.domain.lower" disabled />
                    </div>
                </div>
                <div class="field grid">
                    <label for="high" class="Low-12 mb-2 md:col-2 md:mb-0">Range High</label>
                    <div class="col-12 md:col-10">
                        <InputText id="high" v-model="selectedInput.domain.upper" disabled />
                    </div>
                </div>
                <div class="field grid">
                    <label for="lastdata1" class="col-12 mb-2 md:col-2 md:mb-0">Last Data</label>
                    <div class="col-12 md:col-10">
                        <InputText id="lastdata1" v-model="selectedInput.value" disabled />
                    </div>
                </div>
            </div>
            <div v-if="inputSelectionMade && !inputContinuous" class="card p-fluid">
                <h5>Attribute - Nominal</h5>
                <div class="field grid">
                    <label for="values1" class="col-12 mb-2 md:col-2 md:mb-0">Values</label>
                    <div class="col-12 md:col-10">
                        <InputText id="values1" disabled v-model="selectedInput.domain.values" />
                    </div>
                </div>
                <div class="field grid">
                    <label for="lastdata1" class="col-12 mb-2 md:col-2 md:mb-0">Last Data</label>
                    <div class="col-12 md:col-10">
                        <InputText id="lastdata1" v-model="selectedInput.value" disabled />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 md:col-4">
            <div v-if="inputSelectionMade && !isWebHook" class="card">
                <h5>Manage - Connector</h5>
                <Button label="Change Webhook" severity="success" class="mb-2 mr-2" @click="change2Webhook" />
                <Button label="Manage Connector" severity="success" class="mb-2 mr-2" @click="manageConnector(selectedInput._connector)" />
            </div>
            <div v-if="inputSelectionMade && isWebHook && !RequestAPIChange" class="card">
                <h5>Manage - Webhook</h5>
                <Button label="Webhook Doc." severity="info" class="mb-2 mr-2" @click="popupActivo = true" />
                <Button label="Change to API" severity="help" class="mb-2 mr-2" @click="RequestAPIChange = !RequestAPIChange" />
            </div>
            <div v-if="inputSelectionMade && isWebHook && RequestAPIChange" class="card">
                <h5>Select Connector</h5>
                <Button label="Cancel" severity="help" class="mb-2 mr-2" @click="cancelAPI" />
                <Button label="Save Change" severity="help" class="mb-2 mr-2" :disabled="!connectorSelected" @click="saveAPI" />
                <Dropdown v-model="selectedConnector" :options="freeConnectors" optionLabel="name" placeholder="Select a Connector" class="w-full md:w-56" />
            </div>
        </div>

        <div class="col-12 md:col-4">

            <div class="card flex justify-content-center">
                <h5>up2tom Model</h5>
                <Image :src="up2tomUrl" alt="Image" width="250" preview />
            </div>
        </div>
    </div>

    <!-- <div v-if="inputSelectionMade">
            <div
                v-if="selectedInput.webhook"
                class="col-12 md:col-6"
            >
                <div class="card">
                    <Button
                        label="Change to API"
                        severity="success"
                        class="mb-2 mr-2"
                        @click="RequestAPIChange = !RequestAPIChange"
                    />
                    <Button
                        label="Webhook Doc."
                        severity="success"
                        class="mb-2 mr-2"
                        @click="popupActivo = true"
                    />
                    <Button
                        label="Change to API"
                        severity="warn"
                        class="mb-2 mr-2"
                        :disabled="!inputSelectionMade"
                        @click="changeTOAPI"
                    />
                    <div
                        v-if="RequestAPIChange"
                        class="card flex"
                    >
                        <div class="card flex justify-center">
                            <MultiSelect
                                v-model="selectedConnector"
                                :options="freeConnectors"
                                optionLabel="name"
                                optionValue="._id"
                                @change="changeTOAPI($event)"
                                :virtualScrollerOptions="{ itemSize: 44 }"
                                placeholder="Select Connector"
                                class="w-full md:w-80"
                            />
                        </div>
                    </div>
                </div>
            </div> -->

    <!-- <div
                v-if="!selectedInput.webhook"
                class="col-12 md:col-6"
            >
                <div class="card">
                    <Button
                        label="Change Webhook"
                        severity="success"
                        class="mb-2 mr-2"
                        @click="change2Webhook"
                    />
                    <Button
                        label="Manage Connector"
                        severity="success"
                        class="mb-2 mr-2"
                        @click="manageConnector(selectedInput._id)"
                    />
                </div>
            </div>

            <div v-if="selectedInput.type == 'Continuous'">Decision Input Type - Nominal</div>

            <div
                v-if="inputSelectionMade && selectedInput.type == 'Continuous'"
                class="card flex justify-center"
            >
                <div>{{ selectedInput.value }}</div>
                <br />
                <div>{{ selectedInput.domain.lower }}</div>
                <br />
                <div>{{ selectedInput.domain.upper }}</div>
                <br /> -->
    <!-- <InputText
                    v-model="selectedInput.value"
                    disabled
                    placeholder="No Data Received"
                />
                <InputText
                    v-model="selectedInput.domain.lower"
                    disabled
                    placeholder="No Data Received"
                />
                <InputText
                    v-model="selectedInput.domain.upper"
                    disabled
                    placeholder="No Data Received"
                /> -->
    <VueJsonPretty :selectedItem="model" :jsonPrettyName="modelPrettyName" />
    <VueJsonPretty :selectedItem="selectedInput" :jsonPrettyName="inputPrettyName" />
</template>
